<template>
  <div class="msg">
    <Tabs v-model="showTabs" @on-click="switchBrand">
      <TabPane :label="'All（' + count.all + '）'" name="all"> </TabPane>
      <TabPane :label="'Unread（' + count.notRead + '）'" name="notRead"></TabPane>
      <TabPane :label="'Organzier（' + count.host + '）'" name="host"> </TabPane>
      <TabPane :label="'System（' + count.sys + '）'" name="sys"> </TabPane>
    </Tabs>
    <div class="msg_wrap">
      <div class="msg-list" v-for="item in list" :key="item.id" @click="msgClick(item)">
        <div class="ml-top">
          <div class="ml-top-l">
            <span v-if="item.sendUserType == 1" class="tag zb">Organzier</span>
            <span v-else-if="item.sendUserType == 0" class="tag xt">System</span>
            <!-- <span>{{ item.senderFullName }}</span> -->
            <span style="font-size:12px">{{ item.dataTime }}</span>
          </div>
          <div class="ml-top-r">
            <span class="msg-btn2" v-if="item.hasRead"> <i class="icon iconfont icon-yidu"></i>Read</span>
            <span class="msg-btn s-2" v-else @click.stop="msgClick(item)">Unread</span>
          </div>
        </div>
        <div class="ml-bottom">{{ item.content }}</div>
        <!-- <div class="msg_source" v-if="item.introduction">
          <p class="">Source：{{ item.introduction }}</p>
        </div> -->
      </div>
    </div>
    <div class="no_more mar_bot2" v-if="loading">
      <p>{{ $t("lang.loading") }}</p>
    </div>
    <div class="no_more mar_bot2" v-else-if="finished">
      <p>{{ $t("lang.Its_the_end") }}</p>
    </div>
    <div class="more mar_bot2" @click="moreClick" v-else-if="list && list.length">
      <p>{{ $t("lang.loadMore") }}</p>
    </div>
    <div class="no_more mar_bot2" v-else>{{ $t("lang.No_data") }}！</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "layout",
  data() {
    return {
      showTabs: "all",
      actTabs: "all",
      loading: false, //消息列表加载状态
      finished: false, //消息列表是否完成状态
      list: [], //消息列表
      pageIndex: 1,
      pageSize: 20,
      count: {
        all: 0,
        notRead: 0,
        host: 0,
        sys: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getCountWarp();
    // this.getData();
    this.getDataNew();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      msgRead: "user/msgRead",
    }),
    getCountWarp() {
      // this.getCount("all");
      // this.getCount("notRead");
      // this.getCount("host");
      // this.getCount("sys");

      this.getCountNew("all");
      this.getCountNew("notRead");
      this.getCountNew("host");
      this.getCountNew("sys");
    },
    // 20210419 新 数量
    async getCountNew(type) {
       if(type === "all") {
        // 全部消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: { 
                   page: 1, size: 1,
                   where: {
                    or: {
                      and_XiTongGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        equal_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" }
                      },
                      and_ZhuBanGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 1 },
                        equal_referenceType: { n: "referenceType", v: 200  },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      },
                      and_XiTongXiaoXi: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        notEqual_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      }
                    }
                  }
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3, 4]
              ){
                totalCount
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      } else if(type == 'notRead') {
        // 未读消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: { 
                  page: 1, size: 1,
                  where: {
                    or: {
                      and_XiTongGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        equal_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" }
                      },
                      and_ZhuBanGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 1 },
                        equal_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      },
                      and_XiTongXiaoXi: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        notEqual_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      }
                    },
                    equal_messageState: { n: "messageState", v: 0 }
                  }
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3, 4]
              ){
                totalCount
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      } else if(type === "host") {
        // 主办消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: { 
                  page: 1, size: 1,
                  where: {
                    or: {
                      and_ZhuBanGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 1 },
                        equal_referenceType: { n: "referenceType", v: 200  },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      }
                    }
                  }
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3, 4]
              ){
                totalCount
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      } else {
        // 系统消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: {
                  page: 1
                  size: 1
                  where: {
                    or: {
                      and_XiTongGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 0 }
                        equal_referenceType: { n: "referenceType", v: 200  }
                        equal_langVersion: { n: "langVersion", v: "en" }
                      }
                      and_XiTongXiaoXi: {
                        equal_sendUserType: { n: "sendUserType", v: 0 }
                        notEqual_referenceType: { n: "referenceType" }
                        equal_langVersion: { n: "langVersion", v: "en" }
                        equal_exhibitionId: {
                          n: "exhibitionId"
                          v: "${this.exhibitionId}"
                        }
                      }
                    }
                  }
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3 ,4]
              ){
                totalCount
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      }
      let data = await this.graphqlPost(this.opt);
      let res = JSON.parse(JSON.stringify(data.data.notificationMessageQuery.queryByReceiver2));
      if (res && res.totalCount) {
        this.count[type] = res.totalCount;
      }
    },
    async getCount(type) {
      let query = `
        query($query:_Any!){
          notificationMessageQuery{
            queryByReceiver2(query:$query,receiverId:"${this.user.userId}"){
              totalCount
            }
          }
        }
      `;
      let order = [
        {
          n: "MessageState",
          v: 1,
        },
        {
          n: "ReadAt",
          v: 0,
        },
        {
          n: "ReceiveUserType",
          v: 1,
        },
        {
          n: "publishAt",
          v: 0,
        },
        {
          n: "createAt",
          v: 0,
        },
      ];
      let where = {
        or: {
          equal_exhibitionId: {
            n: "exhibitionId",
            v: this.exhibitionId,
          },
        },
      };
      if (type == "notRead") {
        where={
          or: {
            and_XiTongGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              equal_referenceType: { n: "referenceType",v:200 }
            },
            and_ZhuBanGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 1 },
              equal_referenceType: { n: "referenceType",v:200 },
              equal_langVersion: { n: "langVersion", v: "en" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            },
            and_XiTongXiaoXi: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              notEqual_referenceType: { n: "referenceType" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          },
          equal_messageState: { n: "messageState", v: 0 }
        }
      } else if (type == "host") {
        where={
          or: {
            and_ZhuBanGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 1 },
              equal_referenceType: { n: "referenceType",v:200 },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          }
        }
      } else if (type == "sys") {
        where={
           or: {
            and_XiTongGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              equal_referenceType: { n: "referenceType",v:200 },
            },
            and_XiTongXiaoXi: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              notEqual_referenceType: { n: "referenceType" },
              equal_langVersion: { n: "langVersion", v: "en" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          }
        }
      } else if (type == "all") {
        where={
          or: {
            and_XiTongGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              equal_referenceType: { n: "referenceType",v:200 }
            },
            and_ZhuBanGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 1 },
              equal_referenceType: { n: "referenceType",v:200 },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            },
            and_XiTongXiaoXi: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              notEqual_referenceType: { n: "referenceType" },
              equal_langVersion: { n: "langVersion", v: "en" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          }
        }
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: 10000,
            size: 1,
            where: where,
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.notificationMessageQuery.queryByReceiver2));
      if (res && res.totalCount) {
        this.count[type] = res.totalCount;
      }
    },
    switchBrand(name) {
      if (this.actTabs != this.showTabs) {
        this.actTabs = this.showTabs;
        this.getCountWarp();
        this.$nextTick(() => {
          this.pageIndex = 1;
          // this.getData();
          this.getDataNew();
        });
      }
    },
    async msgClick(item) {
      if (item.hasRead) {
        return;
      }
      let opt = {
        messageId: item.id,
        receiverId: this.user.userId,
      };
      let res = await this.msgRead(opt);
      if (res.result) {
        this.$Message.success("Have read");
        this.$Bus.$emit("refreshMsg");
        this.list.map((e) => {
          if (e.id == item.id) {
            e.hasRead = true;
          }
        });
        this.getCountWarp();
      } else {
        this.$Message.error(res.error);
      }
    },
    // 20210419 新
    async getDataNew() {
      if(this.showTabs === "all") {
        // 全部消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: { 
                   page: ${this.pageIndex}, size: ${this.pageSize},
                   where: {
                    or: {
                      and_XiTongGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        equal_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" }
                      },
                      and_ZhuBanGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 1 },
                        equal_referenceType: { n: "referenceType", v: 200  },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      },
                      and_XiTongXiaoXi: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        notEqual_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      }
                    }
                  }
                  order: [
                    { n: "messageState", v: 1 }
                    { n: "readAt", v: 0 }
                    { n: "receiveUserType", v: 1 }
                    { n: "createAt", v: 0 }
                  ]
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3, 4]
              ){
                hasNextPage
                indexFrom
                pageIndex
                pageSize
                items{
                  content
                  createAt
                  id
                  introduction
                  receiverId
                  messageState
                  publishAt
                  hasRead(receiverId: "${this.user.userId}")
                  senderFullName
                  senderAvatarUrl
                  senderId
                  sendUserType
                  title
                  referenceId
                  referenceType
                  langVersion
                }
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      } else if(this.showTabs == 'notRead') {
        // 未读消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: { 
                  page: ${this.pageIndex}, size: ${this.pageSize},
                  where: {
                    or: {
                      and_XiTongGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        equal_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" }
                      },
                      and_ZhuBanGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 1 },
                        equal_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      },
                      and_XiTongXiaoXi: {
                        equal_sendUserType: { n: "sendUserType", v: 0 },
                        notEqual_referenceType: { n: "referenceType", v: 200 },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      }
                    },
                    equal_messageState: { n: "messageState", v: 0 }
                  }
                  order: [
                    { n: "messageState", v: 1 }
                    { n: "readAt", v: 0 }
                    { n: "receiveUserType", v: 1 }
                    { n: "createAt", v: 0 }
                  ]
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3, 4]
              ){
                hasNextPage
                indexFrom
                pageIndex
                pageSize
                items{
                  content
                  createAt
                  id
                  introduction
                  receiverId
                  messageState
                  publishAt
                  hasRead(receiverId: "${this.user.userId}")
                  senderFullName
                  senderAvatarUrl
                  senderId
                  sendUserType
                  title
                  referenceId
                  referenceType
                  langVersion
                }
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      } else if(this.showTabs === "host") {
        // 主办消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: { 
                  page: ${this.pageIndex}, size: ${this.pageSize},
                  where: {
                    or: {
                      and_ZhuBanGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 1 },
                        equal_referenceType: { n: "referenceType", v: 200  },
                        equal_langVersion: { n: "langVersion", v: "en" },
                        equal_exhibitionId: {
                          n: "exhibitionId",
                          v: "${this.exhibitionId}"
                        }
                      }
                    }
                  }
                  order: [
                    { n: "messageState", v: 1 }
                    { n: "readAt", v: 0 }
                    { n: "receiveUserType", v: 1 }
                    { n: "createAt", v: 0 }
                  ]
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3, 4]
              ){
                hasNextPage
                indexFrom
                pageIndex
                pageSize
                items{
                  content
                  createAt
                  id
                  introduction
                  receiverId
                  messageState
                  publishAt
                  hasRead(receiverId: "${this.user.userId}")
                  senderFullName
                  senderAvatarUrl
                  senderId
                  sendUserType
                  title
                  referenceId
                  referenceType
                  langVersion
                }
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      } else {
        // 系统消息
        let query = `
          query {
            notificationMessageQuery{
              queryByReceiver2(
                query: {
                  page: ${this.pageIndex},
                  size: ${this.pageSize},
                  where: {
                    or: {
                      and_XiTongGongGao: {
                        equal_sendUserType: { n: "sendUserType", v: 0 }
                        equal_referenceType: { n: "referenceType", v: 200  }
                        equal_langVersion: { n: "langVersion", v: "en" }
                      }
                      and_XiTongXiaoXi: {
                        equal_sendUserType: { n: "sendUserType", v: 0 }
                        notEqual_referenceType: { n: "referenceType" }
                        equal_langVersion: { n: "langVersion", v: "en" }
                        equal_exhibitionId: {
                          n: "exhibitionId"
                          v: "${this.exhibitionId}"
                        }
                      }
                    }
                  }
                  order: [
                    { n: "messageState", v: 1 }
                    { n: "readAt", v: 0 }
                    { n: "receiveUserType", v: 1 }
                    { n: "createAt", v: 0 }
                  ]
                }
                receiverId: "${this.user.userId}"
                receiveUserTypes:[3 ,4]
              ){
                hasNextPage
                indexFrom
                pageIndex
                pageSize
                items{
                  content
                  createAt
                  id
                  introduction
                  receiverId
                  messageState
                  publishAt
                  hasRead(receiverId: "${this.user.userId}")
                  senderFullName
                  senderAvatarUrl
                  senderId
                  sendUserType
                  title
                  referenceId
                  referenceType
                  langVersion
                }
              }
            }
          }
        `;
        this.opt = {
          query: query,
          variables: {},
        };
      }
      let data = await this.graphqlPost(this.opt);
      let res = JSON.parse(JSON.stringify(data.data.notificationMessageQuery.queryByReceiver2));
      if (res && res.items.length) {
        res.items.forEach((e) => {
          e.dataTime = e.publishAt ? moment(e.publishAt).format("YYYY/MM/DD HH:mm:ss") : moment(e.createAt).format("YYYY/MM/DD HH:mm:ss");
        });
      }
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      console.log("111111", this.list);
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    async getData() {
      let query = `
      query($query:_Any!){
        notificationMessageQuery{
          queryByReceiver2(query:$query,receiverId:"${this.user.userId}"){
              hasNextPage
              pageIndex
              totalCount
              items{
                content
                createAt
                id
                introduction
                receiverId
                messageState
                publishAt
                hasRead(receiverId:"${this.user.userId}")
                senderFullName
                senderAvatarUrl
                senderId
                sendUserType
                title
                referenceId
                referenceType
              }
            }
          }
        }
      `;
      let order=[
        {
          n:"MessageState",
          v:1
        },
        // {
        //   n:"ReadAt",
        //   v:0
        // },
        {
          n:"createAt",
          v:0
        }
      ]
      let where = {
        or: {
          equal_exhibitionId: {
            n: "exhibitionId",
            v: this.exhibitionId,
          },
        },
      };
      if (this.showTabs == "notRead") {
        where={
          or: {
            and_XiTongGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              equal_referenceType: { n: "referenceType",v:200 }
            },
            and_ZhuBanGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 1 },
              equal_referenceType: { n: "referenceType",v:200 },
              equal_langVersion: { n: "langVersion", v: "en" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            },
            and_XiTongXiaoXi: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              notEqual_referenceType: { n: "referenceType" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          },
          equal_messageState: { n: "messageState", v: 0 }
        }
      } else if (this.showTabs == "host") {
        where={
          or: {
            and_ZhuBanGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 1 },
              equal_referenceType: { n: "referenceType",v:200 },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          }
        }
      } else if (this.showTabs == "sys") {
        where={
           or: {
            and_XiTongGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              equal_referenceType: { n: "referenceType",v:200 },
            },
            and_XiTongXiaoXi: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              notEqual_referenceType: { n: "referenceType" },
              equal_langVersion: { n: "langVersion", v: "en" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          }
        }
      } else if (this.showTabs == "all") {
        where={
          or: {
            and_XiTongGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              equal_referenceType: { n: "referenceType",v:200 }
            },
            and_ZhuBanGongGao: {
              equal_sendUserType: { n: "sendUserType", v: 1 },
              equal_referenceType: { n: "referenceType",v:200 },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            },
            and_XiTongXiaoXi: {
              equal_sendUserType: { n: "sendUserType", v: 0 },
              notEqual_referenceType: { n: "referenceType" },
              equal_langVersion: { n: "langVersion", v: "en" },
              equal_exhibitionId: {
                n: "exhibitionId",
                v: this.exhibitionId
              }
            }
          }
        }
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: where,
            order: order,
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.notificationMessageQuery.queryByReceiver2));
      if (res && res.items.length) {
        res.items.forEach((e) => {
          e.dataTime = e.publishAt ? moment(e.publishAt).format("YYYY/MM/DD HH:mm:ss") : moment(e.createAt).format("YYYY/MM/DD HH:mm:ss");
        });
      }
      
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
      console.log(this.list,'listttt')
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      // this.getData();
      this.getDataNew();
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.msg {
  min-height: 700px;
  background-color: #fff;
  border: solid 1px #ededed;
  ::v-deep {
    .ivu-tabs-bar {
      padding: 0 20px;
    }
    .ivu-tabs-nav .ivu-tabs-tab {
      padding: 14px 16px;
    }
  }
  &-tool {
    display: flex;
    align-items: center;
    .iconfont {
      display: inline-block;
      padding-right: 6px;
    }
    .brand {
      line-height: 49px;
      padding-left: 29px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }
  }
  .msg_wrap {
    margin-top: -10px;
    .msg-list {
      padding: 30px 10px 28px;
      margin: 0 20px;
      border-bottom: 1px solid #ededed;
      cursor: pointer;
      &:hover {
        background: #fafafa;
      }
      .ml-top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &-l {
          width: 50%;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          .tag {
            margin-right: 10px;
            font-size: 12px;
            // width: 40px;
            padding: 0 3px;
            height: 20px;
            border-radius: 3px;
            text-align: center;
            line-height: 20px;
            &.zb {
              background-color: #f2f2f2;
              color: #666;
            }
            &.xt {
              color: #1890ff;
              background: rgba($color: #1890ff, $alpha: 0.1);
            }
          }
        }
        &-r {
          flex: 1;
          text-align: right;
          .msg-btn {
            display: inline-block;
            min-width: 46px;
            text-align: center;
            padding: 1px 5px;
            border-radius: 3px;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            margin-left: 10px;
            font-size: 12px;
            cursor: pointer;
            color: #333;
            &.s-2 {
              min-width: 57px;
              color: #fff;
              background: #ff5e00;
              border: 1px solid #ff5e00;
            }
          }
          .msg-btn2 {
            display: inline-block;
            min-width: 57px;
            text-align: center;
            padding: 1px 5px;
            border-radius: 3px;
            background-color: #fff;
            margin-left: 10px;
            font-size: 12px;
            cursor: pointer;
            color: #333;
            .iconfont {
              font-size: 12px;
              display: inline-block;
              padding-right: 5px;
            }
          }
        }
      }
      .msg_source {
        margin-top: 10px;
        display: flex;
        padding: 6px;
        align-items: center;
        background: #f5f5f5;
        p {
          color: #666;
        }
      }
    }
  }
}
</style>
